const colorDictionary = [
	{ text: 'white', bg: 'burnt-out' },
	{ text: 'white', bg: 'jaywalk' },
	{ text: 'white', bg: 'ulysses' },
	{ text: 'white', bg: 'ansel' },
	{ text: 'white', bg: 'midnight' },
	{ text: 'white', bg: 'poseidon' },
	{ text: 'white', bg: 'burnt-jaywalk' },
	{ text: 'abraham', bg: 'ansel' },
	{ text: 'white', bg: 'deep' },
	{ text: 'white', bg: 'pudding' },
	{ text: 'white', bg: 'thetis' },
	{ text: 'white', bg: 'blossom' },
	{ text: 'white', bg: 'benjamin' },
	{ text: 'white', bg: 'abraham' },
	{ text: 'white', bg: 'casablanca' },
	{ text: 'midnight', bg: 'powder' },
	{ text: 'ulysses', bg: 'powder' },
	{ text: 'ansel', bg: 'powder' },
	{ text: 'matins', bg: 'air' },
	{ text: 'deep', bg: 'air' },
	{ text: 'blossom', bg: 'midnight' },
	{ text: 'white', bg: 'midnight' },
	{ text: 'white', bg: 'pudding' },
	{ text: 'white', bg: 'jaywalk' },
	{ text: 'white', bg: 'ansel' },
	{ text: 'white', bg: 'abraham' },
	{ text: 'air', bg: 'midnight' },
	{ text: 'casablanca', bg: 'midnight' },
	{ text: 'pudding', bg: 'midnight' },
	{ text: 'poseidon', bg: 'midnight' },
	{ text: 'lange', bg: 'midnight' },
	{ text: 'ansel', bg: 'casablanca' },
	{ text: 'air', bg: 'deep' },
	{ text: 'matins', bg: 'lange' },
	{ text: 'matins', bg: 'abraham' },
	{ text: 'matins', bg: 'harriet' },
	{ text: 'deep', bg: 'harriet' },
	{ text: 'matins', bg: 'jaywalk' },
	{ text: 'matins', bg: 'blossom' },
	{ text: 'matins', bg: 'pudding' },
	{ text: 'matins', bg: 'benjamin' },
	{ text: 'thetis', bg: 'midnight' },
	{ text: 'air', bg: 'ansel' },
	{ text: 'burnt-jaywalk', bg: 'brady' },
	{ text: 'pudding', bg: 'jaywalk' },
	{ text: 'abraham', bg: 'ulysses' },
	{ text: 'benjamin', bg: 'matins' },
	{ text: 'thetis', bg: 'deep' },
	{ text: 'matins', bg: 'poseidon' },
	{ text: 'burnt-jaywalk', bg: 'summer' }
];

const deletedColorDictionary = [
	{ text: 'jaywalk', bg: 'blossom' },
	{ text: 'deep', bg: 'benjamin' },
	{ text: 'abraham', bg: 'casablanca' },
	{ text: 'casablanca', bg: 'lange' },
	{ text: 'thetis', bg: 'deep' },
	{ text: 'benjamin', bg: 'abraham' },
	{ text: 'matins', bg: 'deep' },
	{ text: 'matins', bg: 'midnight' },
	{ text: 'benjamin', bg: 'deep' },
	{ text: 'blossom', bg: 'pudding' }
];

/**
 * Generates the hash number for a generated avatar
 * @param avatarSeed the string to hash
 */
export function generateAvatarSeedHash(avatarSeed: string): number {
	let hash = 0; let i ; let char;
	for (i = 0; i < avatarSeed.length; i++) {
		char = avatarSeed.charCodeAt(i);
		hash = ((hash * 32) - hash) + char;

		// integer range overflow check
		hash = hash & hash;
	}
	return Math.abs(hash);
}

/**
 * Generates the seed hash number for a generated avatar
 * @param avatarSeed the string to be hashed
 * @param firstName the first name of the user, corresponding to the first letter of the avatar
 * @param lastName the last name of the user, corresponding to the last letter of the avatar
 * @param isDeleted whether or not the user is deleted
 */
export function getAvatarSVG(avatarSeed: number | string, firstName: string, lastName: string, isDeleted = false, classScope = ''): string {
	if (typeof(avatarSeed) === 'string') {
		avatarSeed = generateAvatarSeedHash(avatarSeed);
	}
	const initials = ((firstName?.trim()[0] ?? '') + (lastName?.trim()[0] ?? '')).toUpperCase();
	// Shrink initials slightly for each M or W
	// transform has to be inline css style for IE
	// (it doesn't take effect in IE, which should support transform but not transform-origin)
	const scale = ((21 - initials.split(/W|M/).length) * .05).toFixed(2);
	const shift = initials.substr(0, 1) === 'J' ? '  translate(14px, -11px);' : initials.substr(1, 1) === 'J' || initials.substr(1, 1) === 'Q' ? ' translate(0, -6px);' : '';
	let colorClasses = isDeleted ? deletedColorDictionary[Math.abs(avatarSeed) % deletedColorDictionary.length] : colorDictionary[avatarSeed % colorDictionary.length];
	if (!colorClasses) // Default if not in the dictionary
		colorClasses = { text: 'white', bg: 'poseidon' };

	return `<svg viewBox="0 0 308 308">
	<circle cx="154" cy="154" r="${isDeleted ? '144' : '150'}" fill="${isDeleted ? 'transparent' : 'currentColor'}" class="${classScope + colorClasses.bg}" stroke="${isDeleted ? 'currentColor' : 'transparent'}" stroke-width="20" stroke-dasharray="45 45" />
    <text x="50%" y="68%" style="transform: scale(${scale}); ${shift} transform-origin: 50%" text-anchor="middle" class="avatar__initials icon--fill ${classScope + colorClasses.text} heavy">${initials}</text>
    </svg>`;
}