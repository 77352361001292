import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { CognitoConfiguration } from '@cognitoforms/utils/clientside-configuration';
import { isPrerender } from '@cognitoforms/utils/utilities';
import Vue from 'vue';
import type { IApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsights: ApplicationInsights = null;

if (!isPrerender() && process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
	try {
		appInsights = new ApplicationInsights({
			config: {
				connectionString: CognitoConfiguration.AppInsightsConnectionString
			}
		});
		appInsights.loadAppInsights();
	}
	catch (e) {
		console.warn('AI failed to initialize:', e);
	}
}

export const AppInsights: () => IApplicationInsights = () => appInsights;

export default Vue.extend({
	name: 'CAppInsights',
	data() {
		return {
			appInsights
		};
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				if (appInsights && !route.meta.customReadyEvent)
					appInsights.stopTrackPage(route.name ?? route.path);
			}
		}
	},
	render() {
		return this.$slots.default;
	}
});