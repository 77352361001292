import { serviceRequest } from './service-request';

export function pinItem(itemId: string, name: string, type: string) {
	return serviceRequest(`svc/user-pins/${itemId}/pin`, { name, type }, { method: 'PUT' });
}

export function unpinItem(itemId: string) {
	return serviceRequest(`svc/user-pins/${itemId}/unpin`, null, { method: 'PUT' });
}

export function reorderUserPins(userPinIds: string[]) {
	return serviceRequest('svc/user-pins/reorder', { userPinIds }, { method: 'PUT' });
}

export async function renameUserPin(userPinId: string, name: string) {
	return serviceRequest(`svc/user-pins/${userPinId}/rename`, { name }, { method: 'PUT' });
}

export async function getUserPins() {
	return serviceRequest('svc/user-pins/all', null, { method: 'GET' });
}