import { interceptResponses, updateSessionOrganization } from './service-request';
import { getSessionInfo, logout } from './session-service';
import type { FeatureFlags } from './feature-flags';
import { isPrerender } from './util';

/**
 * Track information about the user session
 */
const session = { value: null as SessionInfo };
export default session;

/**
 * Track server time header for use in calculating offset
 */
let serverTime: Date = null;

export function setServerTime(date: Date) {
	serverTime = date;
}

/**
 * A class the stores information about the user's session
 */
export class SessionInfo {
	/**
	 * The date and time that the user's session will expire
	 */
	expiration: Date;

	/**
	 * The user's unique ID
	 */
	userId: string;

	/**
	 * The session's unique ID
	 */
	sessionId: string = null;

	/**
	 * The current organization's unique ID
	 */
	organizationId: string = null;

	/**
	 * The current organization authentication Method
	 */
	authMethod: string = null;

	/**
	 * The current organization's unique OrgCode
	 */
	orgCode: string = null;

	/**
	 * The current user's full name
	 */
	userName: string = null;

	/**
	 * The current user's email
	 */
	userEmail: string = null;

	/**
	 * A unique ID for use in telemetry
	 */
	telemetrySessionId: string = null;

	/**
	 * The session token to send to Wordpress
	 * TODO: [MFT] This should be removed with the Wordpress plugin is updated to use the oauth process.
	 */
	sessionToken: string = null;

	/**
	 * The list of feature flags enabled for this user
	 */
	enabledFeatures: FeatureFlags = {};

	isAuthenticated: boolean = null;

	/**
	 * Session creation date
	 */
	created: Date = null;

	/**
	 * offset between client and server time
	 */
	offset: number = 0;

	/**
	 * Whether or not the user is allowed to have their session automatically refreshed
	 */
	allowSessionRefresh: boolean = null;

	/**
	 * String value of the first day of the week according to organization's culture
	 */
	firstDayOfWeek: string = null;
}

/**
 * Gets or establishes the user's session
 */
export async function getActiveSession(): Promise<SessionInfo> {
	if (isPrerender()) return updateSession({});

	// If the session has not yet been established, then attempt to fetch from the server
	if (!session.value)
		await refreshSession();

	// if the session has expired, explicitly call logout to ensure the stale session cookie is cleaned up
	if (session.value.expiration && session.value.expiration <= getServerDate())
		await logout();

	return session.value;
}

/**
 * Refreshes the user's session info
 */
export async function refreshSession(passive: boolean = false): Promise<SessionInfo> {
	let sessionInfo = {};

	try {
		sessionInfo = await getSessionInfo(passive);
	}
	catch (e) {
		console.warn('error occurred while fetching session info', e);
	}

	return updateSession(sessionInfo);
}

export function getServerDate(): Date {
	if (!session.value || !session.value.offset)
		return new Date();

	return new Date(Date.now() - session.value.offset);
}

/**
 * Updates the session info with the given data,
 * either from a header or the `/session/info` service request
 * @param  {{[key:string]:string}} data The new session data
 * @returns The new or updated session info object
 */
function updateSession(data: { [key: string]: string }): SessionInfo {
	// If the session has not already been established, then create a new session object
	if (!session.value)
		session.value = new SessionInfo();
	else if (data.UserId && session.value.userId && session.value.userId !== data.UserId) {
		window.location.href = '/login?logoutReason=UniversalLogout';
	}

	// Account for the difference in server time and reported client time
	// In most cases this will be a small positive number. If it is not, set the session's offset
	// for use when calculating timeout
	if (serverTime) {
		const offset = Date.now() - serverTime.getTime();
		if (offset < 0 || offset > 10000)
			session.value.offset = offset;
		else
			session.value.offset = 0;
	}

	// Update the session with the given data
	Object.keys(data).forEach(key => {
		const value = data[key];
		if (key.toLowerCase() === 'expiration') {
			session.value.expiration = new Date(value);
		}
		else if (key.toLowerCase() === 'userid') {
			session.value.userId = value;
		}
		else if (key.toLowerCase() === 'sessionid') {
			session.value.sessionId = value;
		}
		else if (key.toLowerCase() === 'organizationid') {
			session.value.organizationId = value;
		}
		else if (key.toLowerCase() === 'authmethod') {
			session.value.authMethod = value;
		}
		else if (key.toLowerCase() === 'orgcode') {
			session.value.orgCode = value;
		}
		else if (key.toLocaleLowerCase() === 'username') {
			session.value.userName = value;
		}
		else if (key.toLocaleLowerCase() === 'useremail') {
			session.value.userEmail = value;
		}
		else if (key.toLowerCase() === 'telemetrysessionid') {
			session.value.telemetrySessionId = value;
		}
		else if (key.toLowerCase() === 'sessiontoken') {
			session.value.sessionToken = value;
		}
		else if (key.toLowerCase() === 'enabledfeatures') {
			value.split(',').map(feat => {
				session.value.enabledFeatures[feat] = true;
			});
		}
		else if (key.toLocaleLowerCase() === 'isauthenticated') {
			session.value.isAuthenticated = value === 'true';
		}
		else if (key.toLocaleLowerCase() === 'created') {
			session.value.created = new Date(value);
		}
		else if (key.toLocaleLowerCase() === 'allowsessionrefresh') {
			session.value.allowSessionRefresh = value === 'True';
		}
		else if (key.toLocaleLowerCase() === 'firstdayofweek') {
			session.value.firstDayOfWeek = value;
		}
	});

	updateSessionOrganization(session.value?.organizationId);
	return session.value;
}

interceptResponses(async response => {
	// Update session info if the server sends an 'X-SessionInfo' header,
	// indicating that the session was either established or updated
	const sessionInfoHeader = response.headers.get('X-SessionInfo');
	if (sessionInfoHeader) {
		const sessionInfo = parseKeyValueString(sessionInfoHeader);
		updateSession(sessionInfo);
	}

	serverTime = new Date(response.headers.get('X-Server-Time'));
});

/**
 * Parse a string of key-value pairs (ex: `a=b;c=d;...`) and return the data as a POJO object
 * @param str The raw text to parse
 * @returns An object that contains the key/value pair data from the string
 */
function parseKeyValueString(str: string): { [key: string]: string } {
	const obj = {};
	str.split(';').map(item => {
		const idx = item.indexOf('=');
		const key = item.substring(0, idx);
		const value = item.substring(idx + 1);
		obj[key] = value;
	});
	return obj;
}

export enum SessionStorageKeys {
	LastAutoLogout = 'lastAutoLogout'
}
