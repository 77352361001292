import { serviceRequest } from './service-request';
import type { EntryView } from 'node_modules/@cognitoforms/types/server-types/forms/model/entry-view';
import type { EntryViewsFormData } from 'node_modules/@cognitoforms/types/server-types/forms/model/entry-views-form-data';

export function getEntryView(formId: string, viewId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}`, null, { method: 'GET' });
}

export function getEntryViews(formId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views`, null, { method: 'GET' });
}

export function getEntryViewsFormData(formId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/form-data`, null, { method: 'GET' }) as Promise<EntryViewsFormData>;
}

export function renameEntryView(formId: string, viewId: string, name: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}/rename`, { name }, { method: 'POST' });
}

export function updateEntryView(formId: string, viewId: string, view: EntryView) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}/update`, view, { method: 'POST', useCognitoContentType: true });
}

export function copyEntryView(formId: string, viewId: string, newName: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}/copy`, { newName }, { method: 'POST' });
}

export function deleteEntryView(formId: string, viewId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}`, null, { method: 'DELETE' });
}

export function getFieldAssignments(formId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/assignments`, null, { method: 'GET' });
}