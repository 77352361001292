import type { Handler } from 'mitt';
import mitt from 'mitt';
import { onBeforeUnmount } from 'vue';

export const CreateNewEntryEvent = Symbol('new-entry');
export const RenamePin = Symbol('rename-pin');
export const ExportEntriesEvent = Symbol('export');
export const BulkActionEvent = Symbol('bulk-action');
export const ExpandCollapseEvent = Symbol('expand-collapse');
export const ClearSearchEvent = Symbol('clear-search');
export const PageReadyEvent = Symbol('page-ready');

type Events = {
	[CreateNewEntryEvent]: { formId: string, viewNumber: string };
	[RenamePin]: { pinId: string };
	[ExportEntriesEvent]: { exportAllFields: boolean, selectedEntries: Array<unknown> };
	[BulkActionEvent]: { action: object };
	[ExpandCollapseEvent]: {expandCollapseOption: string};
	[ClearSearchEvent];
	[PageReadyEvent]: { properties: { [key: string]: string }, measurements: { [key: string]: number } };
};

const emitter = mitt<Events>();

type UnsubscribeFunction = () => void;

type UseEventBusReturn<EventKey extends keyof Events, EventData extends Events[EventKey]> = {
	/**
	 * Register an event handler which will be automatically cleaned up when your component is unmounted.
	 * @returns A function to unsubscribe from the event.
	 */
	on: (handler: Handler<EventData>) => UnsubscribeFunction;
	emit: (data: EventData) => void;
	/**
	 * Unregisters all event handlers created via `on`.
	 */
	cleanup: () => void;
};

/**
 * Provides access to functions which can be used to emit or listen for a specific event.
 * @param event The event you wish to interact with.
 * @returns
 */
export function useEventBus<EventKey extends keyof Events, EventData extends Events[EventKey]>(event: EventKey): UseEventBusReturn<EventKey, EventData> {
	const unsubscribes = [];
	function extendedOn(handler: Handler<EventData>): () => void {
		const off = () => emitter.off(event, handler);
		unsubscribes.push(off);
		emitter.on(event, handler);
		return off;
	}

	async function cleanup() {
		unsubscribes.forEach(unsubscribe => unsubscribe());
	}
	onBeforeUnmount(cleanup);

	return {
		on: extendedOn,
		emit: (data: EventData) => emitter.emit(event, data),
		cleanup
	};
}