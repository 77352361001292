import { serviceRequest } from './service-request';
import type { RecentPage } from '@cognitoforms/types/server-types/model/recent-page';

export function addRecentPage(recentPage: RecentPage) {
	return serviceRequest('/svc/page-history', { page: recentPage }, { method: 'POST' });
}

export function getRecentPages(): Promise<RecentPage[]> {
	return serviceRequest('/svc/page-history', null, { method: 'GET' });
}
