import { serviceRequest } from './service-request';

export type FolderResponse = { Name: string, Id: string };

export async function getFolders(includeRoot?: boolean): Promise<FolderResponse[]> {
	return serviceRequest('svc/folders', null, { method: 'GET', query: includeRoot ? { includeRoot: String(includeRoot) } : undefined });
}

export async function createFolder(name: string) {
	return serviceRequest('svc/folders/create', { name }, { method: 'POST' });
}

export async function renameFolder(id: string, name: string) {
	return serviceRequest(`svc/folders/${id}/rename`, { name }, { method: 'PUT' });
}

export async function deleteFolder(id: string) {
	return serviceRequest(`svc/folders/${id}/delete`, null, { method: 'DELETE' });
}

export async function archiveFolder(id: string) {
	return serviceRequest(`svc/folders/${id}/archive`, null, { method: 'PUT' });
}

export async function restoreFolder(id: string) {
	return serviceRequest(`svc/folders/${id}/restore`, null, { method: 'PUT' });
}