import type { FormCopyResponse } from './../../types/server-types/forms/model/form-copy-response';
import type { FormCopyInfo } from '@cognitoforms/types/server-types/forms/model/form-copy-info';
import { serviceRequest } from './service-request';

export function getForms(org: string) {
	return serviceRequest('svc/forms', null, { method: 'GET', query: { org } });
}

export async function newForm(source?: string) {
	return serviceRequest('svc/forms/new', null, { method: 'GET', query: source ? { source } : undefined });
}

export type FormCopyMode = 'WithinOrg' | 'BetweenOrgs' | 'Template';
export function copyForm(sourceOrg: string, destinationOrg: string, destinationFolderId: string | null, copyMode: FormCopyMode, forms: FormCopyInfo[]): Promise<FormCopyResponse> {
	return serviceRequest('svc/forms/copy', { sourceOrg, destinationOrg, destinationFolderId, copyMode, forms }, { method: 'POST' });
}

export function archiveForms(formIds: string[]) {
	return serviceRequest('svc/forms/archive', { formIds }, { method: 'PUT' });
}

export function unarchiveForms(formIds: string[]) {
	return serviceRequest('svc/forms/unarchive', { formIds }, { method: 'PUT' });
}

export function deleteForm(formId: string) {
	return serviceRequest('svc/forms/delete', { formId }, { method: 'DELETE' });
}

export function moveForms(formIds: string[], folderId: string | null = null) {
	return serviceRequest('svc/forms/move', { formIds, folderId }, { method: 'PUT' });
}

export function formExists(org: string, folderId: string | null = null, name : string) {
	return serviceRequest('svc/forms/form-exists', null, { method: 'GET', query: { org, name, folderId: folderId || '' } });
}

export function getRelatedForms(org : string, form : string, isTemplate: boolean = false): Promise<FormCopyInfo[]> {
	return serviceRequest('svc/forms/related-forms', null, { method: 'GET', query: { org, form, isTemplate: isTemplate ? 'true' : 'false' } });
}

export function getEmbedCode(org: string, formId: string) {
	return serviceRequest('svc/forms/embed-code', null, { method: 'GET', query: { org, formId } });
}

export function getFormsView() {
	return serviceRequest('svc/forms/forms-view', null, { method: 'GET' });
}

export function favoriteForm(formId: string, isFavorite: boolean) {
	return serviceRequest('svc/forms/favorite', { formId, isFavorite }, { method: 'POST' });
}

export function getAllRelatedForms(org : string, forms :Array<string>, isTemplate: boolean = false) {
	return serviceRequest('svc/forms/all-related-forms', { org, forms, isTemplate }, { method: 'POST' });
}