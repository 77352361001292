import type { FormDetails } from 'node_modules/@cognitoforms/types/server-types/forms/model/form-details';
import type { LookupReferences } from 'node_modules/@cognitoforms/types/server-types/forms/model/lookup-references';
import { serviceRequest } from './service-request';

export function getFormDetails(formId: string): Promise<FormDetails> {
	return serviceRequest('svc/form-details/model', null, { method: 'GET', query: { formId } });
}

export function exportFormDetails(formIds) {
	return serviceRequest('svc/form-details/download', { formIds }, { method: 'POST' });
}

export function getReferences(formId: string): Promise<LookupReferences> {
	return serviceRequest('svc/form-details/references', null, { method: 'GET', query: { formId } });
}