
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise#description
type PromiseState = 'pending' | 'fulfilled' | 'rejected';

export class Deferred<T = any> implements PromiseLike<T> {
	private _promise: Promise<T>;
	private _resolve: (value?: T) => void;
	private _reject: (reason?: any) => void;
	private _state: PromiseState = 'pending';

	constructor() {
		this._promise = new Promise<T>((resolve, reject) => {
			this._resolve = resolve;
			this._reject = reject;
		});
	}

	get promise() {
		return this._promise;
	}

	get state(): PromiseState {
		return this._state;
	}

	get isPending() {
		return this.state === 'pending';
	}

	resolve(value?: T) {
		if (this.isPending) {
			this._state = 'fulfilled';
			this._resolve(value);
		}
	}

	reject(reason?: any) {
		if (this.isPending) {
			this._state = 'rejected';
			this._reject(reason);
		}
	}

	then<TResult1 = T, TResult2 = never>(onfulfilled?: (value: T) => TResult1 | PromiseLike<TResult1>, onrejected?: (reason: any) => TResult2 | PromiseLike<TResult2>): PromiseLike<TResult1 | TResult2> {
		return this.promise.then(onfulfilled, onrejected);
	}
}
