import type { RouteConfig } from 'vue-router';

const IntegrationRoutes: RouteConfig[] = [
	{
		path: '/integrations',
		component: () => import(/* webpackChunkName: 'Integration' */ 'src/layouts/Integration.vue'),
		meta: {
			auth: 'required'
		},
		children: [
			{
				name: 'cms-integration',
				path: 'cms',
				component: () => import(/* webpackChunkName: 'Integration' */ './cms/FormPicker.vue')
			}
		],
		redirect: {
			path: '/404'
		}
	}
];

export default IntegrationRoutes;
